<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-20'>
    <apsr2022-about-page-navigation class='flex-shrink-0' />
    <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
      <welcome-title title='About APSR & KATRD' />
      <div class='mb-16'>
        <h1 class='text-2xl font-semibold mb-4'>Asian Pacific Society of Respirology</h1>
        <div class='py-4 px-2 rounded flex flex-col lg:flex-row items-center justify-between gap-x-4 lg:gap-x-8 border'>
          <img src='https://apsr2022.org/img/sub/aboutAPSRImg2.png'
            class='w-1/2 lg:w-1/3 py-4 lg:px-6' />
          <div class='w-full lg:w-2/3 p-2'>
            <div class='text-lg text-gray-700 leading-8'>
              Having been established in 1986, the Asian Pacific Society of Respirology (APSR) held its first academic conference in Tokyo, Japan in October, 1988, before turning it into an annual occurrence in 2000. It has enjoyed continued growth and is now recognized as being almost on par with the American Thoracic Society (ATS) and the European Respiratory Society (ERS). APSR is aimed at promoting friendship and the sharing of the latest knowledge and research findings among physicians, nurses, lung function technicians, and respiratory rehabilitation therapists involved in the treatment of respiratory diseases in such Asia-Pacific countries/regions as Japan, Australia, New Zealand, Korea, China, Taiwan, Thailand, Singapore, and the Philippines.
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 class='text-2xl font-semibold mb-4'>KATRD in Service of Humanity’s Respiratory Health and Happiness</h1>
        <div class='py-4 px-2 rounded flex flex-col lg:flex-row items-center justify-between gap-x-4 lg:gap-x-8 border'>
          <img src='https://apsr2022.org/img/sub/aboutAPSRImg1.png'
            class='w-1/2 lg:w-1/3 py-4 lg:px-6'  />
          <div class='w-full lg:w-2/3 p-2'>
            <div class='mb-8 text-lg text-gray-700 leading-8'>
              Started as an academic arm of the Korean National Tuberculosis Association (KNTA) in November 1953, KATRD has become a society that boasts a long history and tradition. Despite significant challenges in the wake of the Korean War, the society’s first academic conference took place on November 7, 1953 thanks to its passion for research and its resolve for tuberculosis eradication. It was re-launched as the Korean Academy of Tuberculosis in 1961 and then renamed the Korean Academy of Tuberculosis and Respiratory Diseases (KATRD) in 1989.
            </div>
            <div class='mb-8 text-lg text-gray-700 leading-8'>
              KATRD’s focus on academic advances through exchanges and harmony among its members has been the catalyst for both its continued growth over the last 65 years and its unrivaled position as a prime organization in the research and treatment of respiratory diseases like tuberculosis. It now has more than 1,620 members and has hosted 127 academic conferences. Its official journal, “Tuberculosis and Respiratory Diseases (TRD),” has been listed on PubMed, contributing to the international dissemination of its research findings, and it strives to elevate the journal to the SCI level.
            </div>
            <div class='text-lg text-gray-700 leading-8'>
              With today’s increased risks of respiratory diseases such as chronic obstructive pulmonary disease, lung cancer, bronchial asthma, and lung injury, KATRAD has designated Lung Day to promote the importance of pulmonary health and giving up smoking. This campaign to increase awareness among the general public has earned KATRD recognition as an exemplary academic society that fulfills its social responsibilities.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Apsr2022AboutPageNavigation from '@/components/apsr2022/Apsr2022AboutPageNavigation.vue'
import WelcomeTitle                from '@/components/apsr2022/WelcomeTitle.vue'

export default {
  name: 'AboutUs',
  components: {
    Apsr2022AboutPageNavigation,
    WelcomeTitle,
  },
}
</script>
